// Generated by Framer (552ec80)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import UserComponent from "./crrqbv9eA";
const UserComponentFonts = getFonts(UserComponent);

const serializationHash = "framer-Y1a3w"

const variantClassNames = {IdDtAmSA6: "framer-v-m04rsz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "IdDtAmSA6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-m04rsz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IdDtAmSA6"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider height={53} width={"53px"} y={((componentViewport?.y || 0) + (((componentViewport?.height || 82) * 0.4878048780487807) - 26.5))}><motion.div className={"framer-3nb0da-container"} layoutDependency={layoutDependency} layoutId={"lLsNIj9xH-container"} style={{rotate: 35}}><UserComponent height={"100%"} id={"lLsNIj9xH"} layoutId={"lLsNIj9xH"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Y1a3w.framer-14f1785, .framer-Y1a3w .framer-14f1785 { display: block; }", ".framer-Y1a3w.framer-m04rsz { height: 82px; overflow: visible; position: relative; width: 74px; }", ".framer-Y1a3w .framer-3nb0da-container { flex: none; height: 53px; left: calc(54.05405405405408% - 53px / 2); position: absolute; top: calc(48.78048780487807% - 53px / 2); width: 53px; z-index: 5; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 82
 * @framerIntrinsicWidth 74
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameryxgRCWqTF: React.ComponentType<Props> = withCSS(Component, css, "framer-Y1a3w") as typeof Component;
export default FrameryxgRCWqTF;

FrameryxgRCWqTF.displayName = "1";

FrameryxgRCWqTF.defaultProps = {height: 82, width: 74};

addFonts(FrameryxgRCWqTF, [{explicitInter: true, fonts: []}, ...UserComponentFonts], {supportsExplicitInterCodegen: true})